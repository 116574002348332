import { extractTwitterHandles } from 'util/strings'

export const RECIPIENT_TYPE_GROUPS = ['to', 'cc', 'bcc']

export const isTwitter = ticket => ticket && ticket.type === 'Twitter'
export const isWidget = ticket => ticket && ticket.type === 'Widget'
export const isFacebook = ticket => ticket && ticket.type === 'Facebook'

export const isDirectMessage = ticket =>
  ticket && isTwitter(ticket) && ticket.subType === 'DirectMessage'

export function getAccountTwitterUsername(ticket) {
  if (!ticket) return ''
  const { subject } = ticket
  if (!subject) return ''

  const usernames = extractTwitterHandles(subject)
  return usernames.length > 0 ? usernames[0] : ''
}
