/* eslint-disable */
// Kevin R: Too many lint issues to fix them all. Also a high probability of introducing
// bugs while trying to fix them
import storage from 'util/storage'
import { difference, areArraysEqual, intersection, uniq } from '../arrays'
import { capitalize } from '../strings'
import { downcaseObjectKeys } from '../objects'
import { toParam } from './sorting/toParam'
import wrapSearchValueInQuotesIfNeeded from './wrapSearchValueInQuotesIfNeeded'
import { toTimestamp } from 'util/date'
import { DATE_SEARCH_QUERY_TYPE, RANGE_SEPARATOR } from './constants'

// Split a query string into parts
// A part is a operator:value pair, or a keyword
// They are separated by spaces (that aren't in quotes), and a double space can
// indicate an empty part.
// Values and keywords can be quoted with ' or "
export function _splitQueryString(queryString) {
  if (!queryString) return null
  const matches = queryString.match(
    /(?:[^\s"]+|(?:")[^"]*(?:"|$))+|(\s(?=\s|$))|(^\s+)/g
  )
  const r = matches && matches.map(x => (x.match(/^\s+$/) ? '' : x))
  return r
}

// Process a query string part into an object with keys and values
export function _processQueryStringPart(queryStringPart) {
  if (queryStringPart.match(/^[":]{1}$/)) {
    return { key: 'keywords', values: [queryStringPart] }
  }
  if (queryStringPart === '') return {}
  const matches = queryStringPart
    .match(/(?:[^:"]+|(?:")[^"]*(?:"))+/g)
    .map(value => {
      const quotedValue = value.match(/^(?:")(.+)(?:")$/)
      return (quotedValue && quotedValue[1]) || value
    })
  if (matches.length === 1 && !queryStringPart.match(/:$/)) {
    return { key: 'keywords', values: matches }
  }
  const key = matches.shift()
  return { key, values: matches }
}

function _flattenKeywordOperations(operations) {
  const newOperations = []
  let foundKeywords = []
  operations.forEach(operation => {
    if (operation.key == 'keywords') {
      foundKeywords = foundKeywords.concat(
        operation.values.map(wrapSearchValueInQuotesIfNeeded)
      )
    } else {
      newOperations.push(operation)
    }
  })
  if (foundKeywords.length > 0) {
    newOperations.push({ key: 'keywords', values: foundKeywords })
  }
  return newOperations
}

// Process query string into an array of key, values operation objects.
function _processQueryString(queryString) {
  if (!queryString) return []
  if (queryString instanceof Array) return queryString
  const queryParts = _splitQueryString(queryString)
  const keyValues = (queryParts || []).map(_processQueryStringPart)
  return _flattenKeywordOperations(keyValues)
}

// Process query object into an array of key, values operation objects.
function _processQueryObject(queryObject) {
  if (!queryObject) return []
  return Object.keys(queryObject).map(key => {
    let values = queryObject[key]
    if (!(values instanceof Array)) values = [values]
    return { key, values }
  }, {})
}

// Often the first step in any search processing so works on all three formats:
//  * search query string - delegates to _processQueryString
//  * search query object - delegates to _processQueryObject
//  * key, value operations array - early return of input
//  * falsey value ("", null, undefined) - early return of an empty array
// Returns an array of object each of which has the following key, value pairs:
//  * key - the left hand part of a "key:value" search operator
//  * values - an array of values, the right hand part of a "key:value" search operator
function _processToOperations(incoming) {
  if (!incoming) return []
  if (incoming instanceof Array) return incoming
  if (typeof incoming === 'string') return _processQueryString(incoming)
  return _processQueryObject(incoming)
}

// Join array of key, value operations objects into a query string.
function _constructQueryString(operationsArray, incomplete) {
  let queryString = operationsArray
    .map(operation => {
      const { key, values } = operation
      if (!key || !values) return null
      return values
        .map(value => {
          if (!value) return null

          const keyString = key === 'keywords' ? '' : `${key}:`
          return `${keyString}${wrapSearchValueInQuotesIfNeeded(value)}`
        })
        .filter(x => !!x)
        .join(' ')
    })
    .filter(x => !!x)
    .join(' ')
  if (incomplete) {
    if (incomplete === true) {
      queryString = `${queryString} `
    } else {
      queryString = `${queryString} ${incomplete}:`
    }
  }
  return queryString
}

// Join array of key, value operation objects into a query object for the store.
function _constructQueryObject(operationsArray) {
  return operationsArray.reduce((queryObject, operation) => {
    if (queryObject[operation.key]) {
      queryObject[operation.key] = Array.from(
        new Set([...queryObject[operation.key], ...operation.values])
      )
    } else {
      queryObject[operation.key] = operation.values
    }
    return queryObject
  }, {})
}

// Replace search operators with internal facing terminology.
// It seems a bit awkward to transform these to strings and then back, but
// at this point regexes are the easiest way to define our aliases.
function _aliasForObject(operationsArray) {
  let query = _constructQueryString(operationsArray)
  query = query.replace(
    /(^|\s)(my\:closed)(?=\s|$)/g,
    '$1state:closed assignee:me'
  )
  query = query.replace(
    /(^|\s)(my\:snoozed)(?=\s|$)/g,
    '$1state:snoozed assignee:me'
  )
  query = query.replace(
    /(^|\s)(my\:open)(?=\s|$)/g,
    '$1state:opened assignee:me'
  )
  query = query.replace(
    /(^|\s)(my\:starred)(?=\s|$)/g,
    '$1starred:all assignee:me'
  )
  query = query.replace(
    /(^|\s)(my\:deleted)(?=\s|$)/g,
    '$1deleted:all assignee:me'
  )
  query = query.replace(
    /(^|\s)(unassigned:[^\s]+)(?=\s|$)/g,
    '$1assignee:unassigned'
  )
  query = query.replace(
    /(^|\s)(group(-|_)unassigned:[^\s]+)(?=\s|$)/g,
    '$1assigned_group:unassigned'
  )
  query = query.replace(/(^|\s)(assignee|agent)\:@?/g, '$1assignee:')
  query = query.replace(
    /(^|\s)(assignee|agent)\:(any|anyone|noone|unassigned|nobody|anybody)(?=\s|$)/g,
    '$1assignee:unassigned'
  )
  query = query.replace(/(^|\s)(tags|tag|labels)(?=\:)/g, '$1label')
  query = query.replace(/(^|\s)(drafts)(?=\:)/g, '$1draft')
  query = query.replace(/(^|\s)(inbox)(?=\:)/g, '$1mailbox')
  query = query.replace(/(^|\s)(group)(?=\:)/g, '$1assigned_group')
  query = query.replace(
    /(^|\s)(is\:unassigned)(?=\s|$)/g,
    '$1assigned_group:unassigned assignee:unassigned'
  )
  query = query.replace(/(^|\s)(is\:assigned)(?=\s|$)/g, '$1assigned:true')
  query = query.replace(/(^|\s)(is\:closed)(?=\s|$)/g, '$1state:closed')
  query = query.replace(/(^|\s)(is\:snoozed)(?=\s|$)/g, '$1state:snoozed')
  query = query.replace(/(^|\s)(is\:unread)(?=\s|$)/g, '$1state:unread')
  query = query.replace(/(^|\s)(is\:deleted)(?=\s|$)/g, '$1deleted:all')
  query = query.replace(/(^|\s)(is\:spam)(?=\s|$)/g, '$1state:spam')
  query = query.replace(/(^|\s)(is\:rated)(?=\s|$)/g, '$1rating:any')
  query = query.replace(
    /(^|\s)(my\:unread)(?=\s|$)/g,
    '$1state:unread assignee:me'
  )
  query = query.replace(
    /(^|\s)((is|state)\:open(ed)?)(?=\s|$)/g,
    '$1state:opened'
  )
  query = query.replace(/(^|\s)(my\:drafts)(?=\s|$)/g, '$1draft:me')
  query = query.replace(/(^|\s)(is\:starred)(?=\s|$)/g, '$1starred:all')
  return _processToOperations(query)
}

// Replace search operators to external customer facing terminology.
// It seems a bit awkward to transform these to strings and then back, but
// at this point regexes are the easiest way to define our aliases.
// The operationsArray should have already been through _aliasForObject,
// _limitValues, and _orderOperations so we only need to alias from the favored
// object format of the operators.
function _aliasForString(operationsArray) {
  let query = _constructQueryString(operationsArray)
  query = query.replace(/(^|\s)label(?=\:)/g, '$1tag')
  query = query.replace(/(^|\s)drafts(?=\:)/g, '$1draft')
  query = query.replace(/(^|\s)mailbox(?=\:)/g, '$1inbox')
  query = query.replace(
    /(^|\s)assigned_group\:unassigned\sassignee:unassigned(?=\s|$)/g,
    '$1is:unassigned'
  )
  query = query.replace(
    /(^|\s)(unassigned:[^\s]+)(?=\s|$)/g,
    '$1assignee:unassigned'
  )
  query = query.replace(
    /(^|\s)(group(-|_)unassigned:[^\s]+)(?=\s|$)/g,
    '$1group:unassigned'
  )
  query = query.replace(/(^|\s)assigned_group(?=\:)/g, '$1group')
  query = query.replace(
    /(^|\s)assignee\:me state\:closed(?=\s|$)/g,
    '$1my:closed'
  )
  query = query.replace(
    /(^|\s)assignee\:me state\:snoozed(?=\s|$)/g,
    '$1my:snoozed'
  )
  query = query.replace(
    /(^|\s)assignee\:me state\:opened(?=\s|$)/g,
    '$1my:open'
  )
  query = query.replace(
    /(^|\s)assignee\:me state\:unread(?=\s|$)/g,
    '$1my:unread'
  )
  query = query.replace(
    /(^|\s)assignee\:me deleted\:all(?=\s|$)/g,
    '$1my:deleted'
  )
  query = query.replace(
    /(^|\s)assignee\:me starred\:all(?=\s|$)/g,
    '$1my:starred'
  )
  query = query.replace(/(^|\s)assigned\:true?(?=\s|$)/g, '$1is:assigned')
  query = query.replace(/(^|\s)draft\:me?(?=\s|$)/g, '$1my:drafts')
  query = query.replace(/(^|\s)state\:opened?(?=\s|$)/g, '$1is:open')
  query = query.replace(/(^|\s)state\:closed?(?=\s|$)/g, '$1is:closed')
  query = query.replace(/(^|\s)state\:snoozed?(?=\s|$)/g, '$1is:snoozed')
  query = query.replace(/(^|\s)state\:unread?(?=\s|$)/g, '$1is:unread')
  query = query.replace(/(^|\s)state\:spam?(?=\s|$)/g, '$1is:spam')
  query = query.replace(/(^|\s)starred\:all(?=\s|$)/g, '$1is:starred')
  query = query.replace(/(^|\s)deleted\:all?(?=\s|$)/g, '$1is:deleted')
  return _processToOperations(query)
}

export function reverseSearchIdValueMap(idValueMap) {
  return Object.keys(idValueMap).reduce((reversingIdValueMap, key) => {
    reversingIdValueMap[key] = Object.keys(idValueMap[key]).reduce(
      (reversedIdValueSet, id) => {
        const value = idValueMap[key][id]
        reversedIdValueSet[value] = id
        return reversedIdValueSet
      },
      {}
    )
    return reversingIdValueMap
  }, {})
}

// Replace label values for IDs.
// Works on array of key, values operation objects.
function _aliasIdsForObject(operationsArray, aliases = {}) {
  return operationsArray.map(operation => {
    const { key, values } = operation
    const downcasedKey = `__${key}_downcased`
    let aliasSet = {}
    if (aliases[downcasedKey]) {
      aliasSet = aliases[downcasedKey]
    } else if (aliases[key]) {
      aliasSet = downcaseObjectKeys(aliases[key])
      aliases[downcasedKey] = aliasSet
    }
    return {
      key,
      values: values.map(value => {
        return aliasSet[value.toLowerCase()] || value
      }),
    }
  }, {})
}

// Replace ID values for labels.
// Works on array of key, values operation objects.
// Reverses aliases and delegates to _aliasIdsForObject
function _aliasIdsForString(operationsArray, aliases = {}) {
  const reversedAliases = reverseSearchIdValueMap(aliases)
  return _aliasIdsForObject(operationsArray, reversedAliases)
}

// Order operations based on _orderOperationsKeyOrder, then alphabetically. Will
// also combine adjacent operations, order the values alphabetically, and remove
// duplicates.
const _orderOperationsKeyOrder = [
  'mailbox',
  'assigned_group',
  'assignee',
  'assigned',
  'draft',
  'starred',
  'state',
  'label',
  'keywords',
]
function _orderOperations(operationsArray) {
  const sortedOperationsArray = operationsArray.sort((a, b) => {
    const aKey = a.key
    const bKey = b.key
    const aKeyIndex = _orderOperationsKeyOrder.indexOf(aKey)
    const bKeyIndex = _orderOperationsKeyOrder.indexOf(bKey)
    if (aKeyIndex > -1 && bKeyIndex < 0) return -1
    if (bKeyIndex > -1 && aKeyIndex < 0) return 1
    if (aKeyIndex < bKeyIndex) return -1
    if (aKeyIndex > bKeyIndex) return 1
    return aKey < bKey ? -1 : aKey > bKey ? 1 : 0
  })
  let previousOperation = {}
  sortedOperationsArray
    .map(operation => {
      if (previousOperation.key === operation.key) {
        previousOperation.values.concat(operation.values)
        return null
      }
      previousOperation = operation
    })
    .filter(x => !!x)
  sortedOperationsArray.forEach(operation => {
    operation.values = uniq(operation.values)
  })
  return sortedOperationsArray
}

// Limit operator values to one value unless listed in
// _limitValuesMultipleValid.
const _limitValuesMultipleValid = [
  'keywords',
  'mailbox',
  'inbox',
  'channel',
  'state',
]
// One day this may include other operators, labels being an obvious one, but
// will need to be supported by underlying search first.
function _limitValues(operationsArray) {
  const existingKeys = []
  return operationsArray
    .reverse()
    .map(operation => {
      const { key, values } = operation
      if (_limitValuesMultipleValid.indexOf(key) > -1) {
        return operation
      } else if (existingKeys.indexOf(key) > -1) {
        return null
      }
      existingKeys.push(key)
      return { key, values: [values[values.length - 1]] }
    })
    .filter(x => !!x)
    .reverse()
}

// Find and return the requested value from a query
function _findSingleOperatorValue(operationsArray, operator) {
  const operation = operationsArray.find(operation => {
    return operation.key === operator
  })
  return (
    operation &&
    operation.values &&
    operation.values[operation.values.length - 1]
  )
}

// Remove the provided operator from the search
function _ignoreOperator(operationsArray, operator) {
  return operationsArray.filter(operation => {
    return operation.key !== operator
  })
}

// Construct a search query string from any of the following:
//  * search query string - will return with "favored" aliases and order of operators
//  * search query object
export function constructSearchQueryString(
  incomingQueryObject,
  idValueMap = {},
  options = {}
) {
  let query = _processToOperations(incomingQueryObject)
  query = _aliasForObject(query)
  // query = _processQueryString(query)
  query = _limitValues(query)
  query = _orderOperations(query)
  query = _aliasIdsForString(query, idValueMap)
  query = _aliasForString(query)
  query = _constructQueryString(query, options.incomplete)
  return query
}

export function constructEncodedSearchQueryString(
  incomingQueryObject,
  idValueMap = {},
  options = {}
) {
  const { sortOrder } = options
  const encodedQueryString = encodeURIComponent(
    constructSearchQueryString(incomingQueryObject, idValueMap, options)
  )
  if (!sortOrder) return encodedQueryString
  return `${encodedQueryString}${toParam(sortOrder)}`
}

export function deconstructEncodedSearchQueryString(string) {
  return constructSearchQueryObject(decodeURIComponent(string))
}

// Construct a search query ID from any of the following:
//  * search query string
//  * search query object
// This is is very similar to a search query string except IDs are kept inplace
// instead of substituting for labels.
export function constructSearchQueryId(incomingQueryObject, idValueMap = {}) {
  let query = _processToOperations(incomingQueryObject)
  query = _aliasForObject(query)
  // query = _processQueryString(query)
  query = _limitValues(query)
  query = _orderOperations(query)
  query = _aliasIdsForObject(query, idValueMap)
  query = _aliasForString(query)
  query = _constructQueryString(query)
  return query
}

export function constructMailboxLessSearchQueryId(
  incomingQueryObject,
  idValueMap = {}
) {
  const queryObjectCopy = { ...incomingQueryObject }
  delete queryObjectCopy.mailbox
  return constructSearchQueryId(queryObjectCopy, idValueMap)
}

// Construct a search query object for storing in state from any of the following:
//  * search query string
//  * search query object
// This is is very similar to a search query string except IDs are kept inplace
// instead of substituting for labels.
export function constructSearchQueryObject(incomingQuery, idValueMap = {}) {
  let query = _processToOperations(incomingQuery)
  query = _aliasForObject(query)
  // query = _processQueryString(query)
  query = _limitValues(query)
  query = _orderOperations(query)
  query = _aliasIdsForObject(query, idValueMap)
  query = _constructQueryObject(query)
  return query
}

export function isDateSearchFilter(filterString, suggestionList) {
  const dateQueries = suggestionList.filter(
    item => item.type === DATE_SEARCH_QUERY_TYPE
  )
  return dateQueries.some(query => filterString.startsWith(query.searchQuery))
}

const submittedSearchQueryDataKey = 'submittedSearchQueryIdWithQueryString'
export const storeSubmittedQueryIdWithQueryString = submittedSearchQueryIdWithQueryString => {
  storage.set(
    submittedSearchQueryDataKey,
    JSON.stringify(submittedSearchQueryIdWithQueryString)
  )
}

// Get unique mailbox names
export const getUniqueMailboxesFromSearches = (listOne, listTwo) => {
  return Array.from(new Set([...(listOne || []), ...(listTwo || [])]))
}

export const getSearchMailboxesFromQueryString = (queryString, currentPart) => {
  if (!queryString || currentPart?.operator === 'channel') return []
  const result = _splitQueryString(queryString)
    .map(part => {
      const keyWithValues = _processQueryStringPart(part)
      if (keyWithValues.key === 'inbox') {
        return keyWithValues.values[0]
      }
      return undefined
    })
    .filter(Boolean)

  return getUniqueMailboxesFromSearches(result)
}

const createMailboxFilters = (searchMailboxIds = [], queryString) => {
  const mailboxesFromQueryString = getSearchMailboxesFromQueryString(
    queryString
  )
  return searchMailboxIds
    .map(id => {
      const inboxFilter = `inbox:${id}`
      if (mailboxesFromQueryString.includes(inboxFilter)) {
        return undefined
      }
      return inboxFilter
    })
    .filter(Boolean)
}

export const getSubmittedQueryStringWithMailboxes = ({
  queryString,
  searchMailboxIds,
  shouldIncludeSearchMailboxes = true,
}) => {
  if (!shouldIncludeSearchMailboxes) return queryString
  const mailboxFilters = createMailboxFilters(searchMailboxIds, queryString)
  const fullQueryString = [...mailboxFilters, queryString]
    .filter(Boolean)
    .join(' ')
  return fullQueryString
}
